import React, { useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { HOME_SCREEN_TABS, SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { Modal } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { ExplorerModalBody } from '@/explorer/ExplorerModalBody.molecule';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { ACL_MODAL_CHANGE_CHANNEL, emitBroadcastChannelMessage } from '@/services/broadcastChannel.utilities';
import { equalsIgnoreCase, truncate } from '@/utilities/utilities';
import { successToast } from '@/utilities/toast.utilities';
import { CancelAndSave } from '@/core/CancelAndSave.molecule';
import { sqExploreFolderModalStore, sqHomeScreenStore } from '@/core/core.stores';
import { canModifyWorkbook } from '@/services/authorization.service';
import { getTabFolderName, moveItem, translateBreadcrumb } from '@/utilities/homescreen.utilities';
import { getTabFolder } from '@/utilities/homescreen.helpers';
import {
  clearItemsHomescreen,
  loadFolder,
  loadFolderTree,
  setCurrentFolder,
  setCurrentTab,
} from '@/homescreen/homescreen.actions';
import * as sqExploreFolderModalActions from '@/explorer/exploreFolderModal.actions';
import { getParentFolderId } from '@/explorer/exploreFolderModal.actions';
import { useNavigate } from 'react-router-dom';
import { getFolderLink } from '@/main/routing.utilities';
import { SelectedItem } from '@/core/SelectItemSearchWidet.molecule';

interface FolderExplorerModalProps {
  corporateDriveMessage?: boolean;
  isAclModalLocationInHeader?: boolean;
  onClose: () => void;
  item: any;
  fetchInherited?: any;
  itemId?: string;
  itemName?: string;
  currentTab: string;
  aclModified?: any;
  onSelect?: (item: SelectedItem) => void;
  onSave?: () => void;
  title?: string;
  submitLabel?: string;
  inFormComponent?: boolean;
}

export const FolderExplorerModal: React.FunctionComponent<FolderExplorerModalProps> = (props) => {
  const {
    currentTab,
    item,
    onClose,
    fetchInherited,
    corporateDriveMessage,
    itemId,
    itemName,
    aclModified,
    isAclModalLocationInHeader,
    onSelect,
    onSave,
    title,
    submitLabel,
    inFormComponent = false,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const folder = useFluxPath(sqExploreFolderModalStore, () => sqExploreFolderModalStore.folder);
  const initialFolderId = useFluxPath(sqExploreFolderModalStore, () => sqExploreFolderModalStore.initialFolderId);
  const localizedFolder = translateBreadcrumb(folder);

  useEffect(() => {
    if (item && inFormComponent) {
      getParentFolderId(currentTab, item);
    } else {
      sqExploreFolderModalActions.getInitialFolder({
        currentTab: corporateDriveMessage ? HOME_SCREEN_TABS.CORPORATE : currentTab,
        item,
        forceCorporate: corporateDriveMessage,
      });
    }
  }, []);

  const close = (movedItem = false) => {
    if (movedItem) {
      clearItemsHomescreen();
    }
    sqExploreFolderModalActions.clear();
    setCurrentTab(currentTab);
    onClose();
  };

  const updateMovedItems = () => {
    return successToast({
      messageKey: 'WORKBENCH.ITEM_MOVED',
      messageParams: {
        item: corporateDriveMessage ? itemName : item.name,
        folder: folder.name,
      },
    });
  };

  const moveItemToFolder = () => {
    const currentFolderId = folder ? folder.id : SEARCH_ITEM_LOCATIONS.MY_FOLDER;
    return moveItem(corporateDriveMessage ? itemId : item.id, currentFolderId).then((response: any) => {
      if (!corporateDriveMessage) {
        close(true);
      }
      let folderId: string | null;
      let currentTab: string | null;
      let tabFolderPromise;
      if (!folder) {
        tabFolderPromise = getTabFolder(HOME_SCREEN_TABS.MY_FOLDER);
      } else {
        tabFolderPromise = getTabFolderName(currentFolderId).then((tabFolder) => {
          folderId = tabFolder ? null : currentFolderId;
          currentTab = tabFolder || null;
        });
      }
      return tabFolderPromise.then(() => {
        if (item !== undefined && !corporateDriveMessage) {
          return loadFolderTree(folderId, currentTab).then(() => {
            navigate(getFolderLink(currentFolderId));
            requestAnimationFrame(updateMovedItems);
            onClose();
          });
        } else {
          emitBroadcastChannelMessage(ACL_MODAL_CHANGE_CHANNEL);
          setCurrentFolder(currentFolderId);
          setCurrentTab(currentTab ?? '');
          updateMovedItems();
          fetchInherited();
          onClose();
          if (!isAclModalLocationInHeader && !aclModified) {
            return loadFolderTree(folderId, currentTab).then(() => navigate(getFolderLink(currentFolderId)));
          } else {
            return loadFolder(folderId, currentTab);
          }
        }
      });
    });
  };

  const onSelectFolder = () => {
    if (onSelect) {
      const currentFolderId = folder ? folder.id : SEARCH_ITEM_LOCATIONS.MY_FOLDER;
      onSelect({ id: currentFolderId, name: folder?.name ?? '' } as SelectedItem);
      onSave?.();
      close();
    } else {
      moveItemToFolder();
    }
  };

  const isNotCurrentFolder = !folder || folder?.id !== sqHomeScreenStore.currentFolderId;
  const isNotFilteringByPublicOrPinned =
    sqExploreFolderModalStore.tableFilter !== SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC &&
    sqExploreFolderModalStore.tableFilter !== SEARCH_ITEM_LOCATIONS.PINNED;
  const isNotFilteringByAll = sqExploreFolderModalStore.tableFilter !== SEARCH_ITEM_LOCATIONS.ALL;
  const isFolderValidForMove =
    canModifyWorkbook(folder, false) &&
    folder &&
    !equalsIgnoreCase(folder?.id, initialFolderId!) &&
    !equalsIgnoreCase(folder?.id, item?.id) &&
    _.isUndefined(
      _.find(folder?.ancestors, (ancestor) => {
        return equalsIgnoreCase(ancestor.id, item?.id);
      }),
    );

  // If folder is undefined it could be that we are trying to move to the "My Folder" that has yet to be created
  const canMoveItem = () => {
    if (corporateDriveMessage) {
      return isNotCurrentFolder && isNotFilteringByPublicOrPinned;
    }
    if (!folder && isNotFilteringByPublicOrPinned) {
      return isNotFilteringByAll;
    }
    return isFolderValidForMove;
  };

  const canMoveItemInFormComponent = inFormComponent && isNotFilteringByAll && isNotFilteringByPublicOrPinned;

  const renderButtons = (
    <div className="flexColumnContainer flexJustifyEnd pt15">
      <CancelAndSave
        cancelFn={() => close()}
        cancelBtnTestId="cancelMoveBtn"
        submitFn={onSelectFolder}
        submitBtnTestId="moveToBtn"
        submitBtnLabel={submitLabel ?? 'WORKBENCH.MOVE_HERE'}
        btnDisabled={!canMoveItem() && !canMoveItemInFormComponent}
        submitClassNames={classNames({
          'disabledLook disabledBehavior': !canMoveItem() && !canMoveItemInFormComponent,
        })}
        values={[]}
      />
    </div>
  );

  return (
    <Modal
      open={true}
      onClose={close}
      testId="folderExplorerModal"
      title=""
      customHeader={
        <h3 className="modal-title flexColumnContainer flexAlignCenter textAlignLeft">
          <HoverTooltip text={localizedFolder || ''} delay={500}>
            <span data-testid="folderExplorerTitle">
              {title ? t(title) : t('WORKBENCH.MOVE_TO_FOLDER')}: {truncate(localizedFolder, 50, 20)}
            </span>
          </HoverTooltip>
        </h3>
      }
      modalFooter={renderButtons}>
      <div className=" max-height-475 overflowAuto">
        <ExplorerModalBody
          show={true}
          store={sqExploreFolderModalStore}
          actions={sqExploreFolderModalActions}
          allowedTypes={['Folder']}
        />
      </div>
    </Modal>
  );
};
